import {getAbilityInfo} from '../../../../api/api'
import session from "../../../../store/store";
export default {
    data() {
        return {
            printObj: {
                id: "printMe",
                popTitle: "",
                extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
                type: "html"
            },
            userData: {
                name:'',
                idcard:''
            },
            uuid:{},
            result:{},
        }
    },
    mounted() {
        // this.init()
    },
    methods: {
        getDataWithUser(data) {
            this.userData.name = session.getCustomerData().name;
            this.userData.idcard = session.getCustomerData().idcard;
            this.uuid = data.uuid;
            this.getAbilityInfo();
        },
        getDataWithUnit(data) {
            this.userData.name = data.userName;
            this.userData.idcard = data.idcard;
            this.uuid = data.uuid;
            this.getAbilityInfo();
        },
        cancel() {
            this.$emit("cancel")
        },


        getAbilityInfo() {
            let param = {
                uuid: this.uuid,
            }
            getAbilityInfo(param).then(res => {
                if (res.data.code == 200) {
                    this.result = res.data.dataInfo;
                    this.setAbilityData()
                }
            })
        },

        /**
         * 能力评估初始化打印信息
         */
         setAbilityData() { 
            var abilityData = JSON.parse(this.result.details);
            (document.getElementById("userName")).innerText = this.userData.name;
            (document.getElementById("idCard")).innerText = this.userData.idcard;
            (document.getElementById("assessTime")).innerText = this.result.assessTime;
            (document.getElementById("nextAssessTime")).innerText = this.result.nextAssessTime;
            (document.getElementById("eat")).innerText = abilityData.dailyCalculate.eat.content;
            (document.getElementById("scoreeat")).innerText = abilityData.dailyCalculate.eat.score;

            (document.getElementById("shower")).innerText = abilityData.dailyCalculate.shower.content;
            (document.getElementById("scoreshower")).innerText = abilityData.dailyCalculate.shower.score;

            (document.getElementById("decorate")).innerText = abilityData.dailyCalculate.decorate.content;
            (document.getElementById("scoredecorate")).innerText = abilityData.dailyCalculate.decorate.score;

            (document.getElementById("dress")).innerText = abilityData.dailyCalculate.dress.content;
            (document.getElementById("scoredress")).innerText = abilityData.dailyCalculate.dress.score;

            (document.getElementById("shit")).innerText = abilityData.dailyCalculate.shitControl.content;
            (document.getElementById("scoreshit")).innerText = abilityData.dailyCalculate.shitControl.score;

            (document.getElementById("urine")).innerText = abilityData.dailyCalculate.shitControl.content;
            (document.getElementById("scoreurine")).innerText = abilityData.dailyCalculate.shitControl.score;

            (document.getElementById("toilet")).innerText = abilityData.dailyCalculate.toilet.content;
            (document.getElementById("scoretoilet")).innerText = abilityData.dailyCalculate.toilet.score;

            (document.getElementById("bedchairtransfer")).innerText = abilityData.dailyCalculate.bedChairTransfer.content;
            (document.getElementById("scorebedchairtransfer")).innerText = abilityData.dailyCalculate.bedChairTransfer.score;

            (document.getElementById("groundwalk")).innerText = abilityData.dailyCalculate.groundWalk.content;
            (document.getElementById("scoregroundwalk")).innerText = abilityData.dailyCalculate.groundWalk.score;

            (document.getElementById("updownstairs")).innerText = abilityData.dailyCalculate.upDownStairs.content;
            (document.getElementById("scoreupdownstairs")).innerText = abilityData.dailyCalculate.upDownStairs.score;

            (document.getElementById("dailycalculatestate")).innerText = abilityData.dailyCalculate.result.content;
            (document.getElementById("dailycalculatescore")).innerText = abilityData.dailyCalculate.result.score;


            (document.getElementById("cognitivefunction")).innerText = abilityData.mentalState.cognitiveFunction.content;
            (document.getElementById("scorecognitivefunction")).innerText = abilityData.mentalState.cognitiveFunction.score;

            (document.getElementById("aggressivebehavior")).innerText = abilityData.mentalState.aggressiveBehavior.content;
            (document.getElementById("scoreaggressivebehavior")).innerText = abilityData.mentalState.aggressiveBehavior.score;

            (document.getElementById("depression")).innerText = abilityData.mentalState.depression.content;
            (document.getElementById("scoredepression")).innerText = abilityData.mentalState.depression.score;

            (document.getElementById("mentalstatestate")).innerText = abilityData.mentalState.result.content;
            (document.getElementById("mentalstatescore")).innerText = abilityData.mentalState.result.score;

            (document.getElementById("levelsonsciousness")).innerText = abilityData.perceptionCommunication.levelConsciousness.content;
            (document.getElementById("scorelevelconsciousness")).innerText = abilityData.perceptionCommunication.levelConsciousness.score;

            (document.getElementById("vision")).innerText = abilityData.perceptionCommunication.vision.content;
            (document.getElementById("scorevision")).innerText = abilityData.perceptionCommunication.vision.score;

            (document.getElementById("hearing")).innerText = abilityData.perceptionCommunication.hearing.content;
            (document.getElementById("scorehearing")).innerText = abilityData.perceptionCommunication.hearing.score;

            (document.getElementById("communication")).innerText = abilityData.perceptionCommunication.communication.content;
            (document.getElementById("scorecommunication")).innerText = abilityData.perceptionCommunication.communication.score;

            (document.getElementById("percecommunistate")).innerText = abilityData.perceptionCommunication.result.content;
            (document.getElementById("percecommuniscore")).innerText = abilityData.perceptionCommunication.result.score;


            (document.getElementById("lifeability")).innerText = abilityData.socialAssessment.lifeAbility.content;
            (document.getElementById("scorelifeability")).innerText = abilityData.socialAssessment.lifeAbility.score;

            (document.getElementById("abilitywork")).innerText = abilityData.socialAssessment.abilityWork.content;
            (document.getElementById("scoreabilitywork")).innerText = abilityData.socialAssessment.abilityWork.score;

            (document.getElementById("timeorientation")).innerText = abilityData.socialAssessment.timeOrientation.content;
            (document.getElementById("scoretimeorientation")).innerText = abilityData.socialAssessment.timeOrientation.score;

            (document.getElementById("peopleoriented")).innerText = abilityData.socialAssessment.peopleOriented.content;
            (document.getElementById("scorepeopleoriented")).innerText = abilityData.socialAssessment.peopleOriented.score;

            (document.getElementById("socialskills")).innerText = abilityData.socialAssessment.socialSkills.content;
            (document.getElementById("scoresocialskills")).innerText = abilityData.socialAssessment.socialSkills.score;

            (document.getElementById("socialassessmentstate")).innerText = abilityData.socialAssessment.result.content;
            (document.getElementById("socialassessmentscore")).innerText = abilityData.socialAssessment.result.score;

            (document.getElementById("preliminarycompetencelevel")).innerText = abilityData.socialAssessment.result.preliminary_content;
            (document.getElementById("gradechangeclause")).innerText = abilityData.gradeChangeClause.gradeChangeClause.content;

            (document.getElementById("finallevel")).innerText = abilityData.gradeChangeClause.result.preliminary_content;
        },

    }
}