<template>
  <div class="page">
    <ls-title-header @back="cancel" title="报告详情"></ls-title-header>
    <div id="printMe" ref="printContent" class="divboeder">
      <div class="pageDiv">
        <div class="divprint">
          <div class="titleDiv">
            <span>老年人能力评估报告</span>
          </div>
          <div class="userInfoDiv">
            <div><span>姓名：</span><span id="userName"></span></div>
            <div><span>身份证号：</span><span id="idCard"></span></div>
            <div><span>评估日期：</span><span id="assessTime"></span></div>
            <div>
              <span>下次评估日期：</span><span id="nextAssessTime"></span>
            </div>
          </div>
          <table class="table">
            <!-- 标题栏 -->
            <tr>
              <th>一级指标</th>
              <th>二级指标</th>
              <th style="width: 150px">说明</th>
              <th style="width: 20px">分值</th>
            </tr>
            <!-- 标题栏 -->

            <tr>
              <td rowspan="11">日常生活活动</td>
            </tr>
            <!-- 进食 -->
            <tr>
              <td>进食</td>
              <td class="tdLeft" id="eat"></td>
              <td><span id="scoreeat"></span></td>
            </tr>
            <!-- 进食 -->

            <!-- 洗澡 -->
            <tr>
              <td>洗澡</td>
              <td class="tdLeft" id="shower"></td>
              <td><span id="scoreshower"></span></td>
            </tr>
            <!-- 洗澡 -->

            <!-- 修饰 -->
            <tr>
              <td>修饰</td>
              <td class="tdLeft" id="decorate"></td>
              <td><span id="scoredecorate"></span></td>
            </tr>
            <!-- 修饰 -->

            <!-- 穿衣 -->
            <tr>
              <td>穿衣</td>
              <td class="tdLeft" id="dress"></td>
              <td><span id="scoredress"></span></td>
            </tr>
            <!-- 穿衣 -->

            <!-- 大便控制 -->
            <tr>
              <td>大便控制</td>
              <td class="tdLeft" id="shit"></td>
              <td><span id="scoreshit"></span></td>
            </tr>
            <!-- 大便控制 -->

            <!-- 小便控制 -->
            <tr>
              <td>小便控制</td>
              <td class="tdLeft" id="urine"></td>
              <td><span id="scoreurine"></span></td>
            </tr>
            <!-- 小便控制 -->

            <!-- 如厕 -->
            <tr>
              <td>如厕</td>
              <td class="tdLeft" id="toilet"></td>
              <td><span id="scoretoilet"></span></td>
            </tr>
            <!-- 如厕 -->

            <!-- 床椅转移 -->
            <tr>
              <td>床椅转移</td>
              <td class="tdLeft" id="bedchairtransfer"></td>
              <td><span id="scorebedchairtransfer"></span></td>
            </tr>
            <!-- 床椅转移 -->

            <tr>
              <td>平地行走</td>
              <td class="tdLeft" id="groundwalk"></td>
              <td><span id="scoregroundwalk"></span></td>
            </tr>
            <!-- 平地行走 -->

            <!-- 上下楼梯 -->
            <tr>
              <td>上下楼梯</td>
              <td class="tdLeft" id="updownstairs"></td>
              <td><span id="scoreupdownstairs"></span></td>
            </tr>
            <tr>
              <td
                class="tdLeft"
                colspan="4"
                style="text-align: center !important; font-weight: 600"
              >
                <span>日常生活活动-分级：</span
                ><span style="color: red" id="dailycalculatestate"></span>
                <span
                  style="float: right; padding-top: 3px"
                  id="dailycalculatescore"
                  >0</span
                ><span style="float: right">共计：</span>
              </td>
            </tr>
            <!-- 上下楼梯 -->

            <tr>
              <td rowspan="4">精神状态</td>
            </tr>
            <!-- 认知功能 -->
            <tr>
              <td>认知功能</td>
              <td class="tdLeft" id="cognitivefunction"></td>
              <td><span id="scorecognitivefunction"></span></td>
            </tr>
            <!-- 认知功能 -->

            <!-- 攻击行为 -->
            <tr>
              <td>攻击行为</td>
              <td class="tdLeft" id="aggressivebehavior"></td>
              <td><span id="scoreaggressivebehavior"></span></td>
            </tr>
            <!-- 攻击行为 -->

            <!-- 抑郁症状 -->
            <tr>
              <td>抑郁症状</td>
              <td class="tdLeft" id="depression"></td>
              <td><span id="scoredepression"></span></td>
            </tr>
            <!-- 抑郁症状 -->
            <tr>
              <td
                class="tdLeft"
                colspan="4"
                style="text-align: center !important; font-weight: 600"
              >
                <span>精神状态-分级：</span
                ><span style="color: red" id="mentalstatestate"></span>
                <span
                  style="float: right; padding-top: 3px"
                  id="mentalstatescore"
                  >0</span
                ><span style="float: right">共计：</span>
              </td>
            </tr>

            <tr>
              <td rowspan="5">感知觉与沟通</td>
            </tr>
            <!-- 意识水平 -->
            <tr>
              <td>意识水平</td>
              <td class="tdLeft" id="levelsonsciousness"></td>
              <td><span id="scorelevelconsciousness"></span></td>
            </tr>
            <!-- 意识水平 -->

            <!-- 视力 -->
            <tr>
              <td>视力</td>
              <td class="tdLeft" id="vision"></td>
              <td><span id="scorevision"></span></td>
            </tr>
            <!-- 视力 -->
            <!-- 听力 -->
            <tr>
              <td>听力</td>
              <td class="tdLeft" id="hearing"></td>
              <td><span id="scorehearing"></span></td>
            </tr>
            <!-- 听力 -->

            <!-- 沟通交流 -->
            <tr>
              <td>沟通交流</td>
              <td class="tdLeft" id="communication"></td>
              <td><span id="scorecommunication"></span></td>
            </tr>
            <!-- 沟通交流 -->
            <tr>
              <td
                class="tdLeft"
                colspan="4"
                style="text-align: center !important; font-weight: 600"
              >
                <span>感知觉与沟通-分级：</span
                ><span style="color: red" id="percecommunistate"></span>
                <span
                  style="float: right; padding-top: 3px"
                  id="percecommuniscore"
                  >0</span
                ><span style="float: right">共计：</span>
              </td>
            </tr>

            <tr>
              <td rowspan="6">社会参与评估</td>
            </tr>
            <!-- 生活能力 -->
            <tr>
              <td>生活能力</td>
              <td class="tdLeft" id="lifeability"></td>
              <td><span id="scorelifeability"></span></td>
            </tr>
            <!-- 生活能力 -->

            <!-- 工作能力 -->
            <tr>
              <td>工作能力</td>
              <td class="tdLeft" id="abilitywork"></td>
              <td><span id="scoreabilitywork"></span></td>
            </tr>
            <!-- 工作能力 -->

            <!-- 时间/空间定向  -->
            <tr>
              <td>时间/空间定向</td>
              <td class="tdLeft" id="timeorientation"></td>
              <td><span id="scoretimeorientation"></span></td>
            </tr>
            <!-- 时间/空间定向  -->

            <!-- 人物定向 -->
            <tr>
              <td>人物定向</td>
              <td class="tdLeft" id="peopleoriented"></td>
              <td><span id="scorepeopleoriented"></span></td>
            </tr>
            <!-- 人物定向 -->

            <!-- 社会交往能力  -->
            <tr>
              <td>社会交往能力</td>
              <td class="tdLeft" id="socialskills"></td>
              <td><span id="scoresocialskills"></span></td>
            </tr>
            <!-- 社会交往能力  -->
            <tr>
              <td
                class="tdLeft"
                colspan="4"
                style="text-align: center !important; font-weight: 600"
              >
                <span>社会参与评估-分级：</span
                ><span style="color: red" id="socialassessmentstate"></span>
                <span
                  style="float: right; padding-top: 3px"
                  id="socialassessmentscore"
                  >0</span
                ><span style="float: right">共计：</span>
              </td>
            </tr>
            <tr>
              <td
                class="tdLeft"
                colspan="4"
                style="text-align: center !important; font-weight: 600"
              >
                <span>能力初步等级：</span
                ><span
                  style="color: red"
                  id="preliminarycompetencelevel"
                ></span>
              </td>
            </tr>

            <tr>
              <td rowspan="3">等级变更条款</td>
            </tr>
            <tr>
              <td class="tdLeft" colspan="3">
                <span id="gradechangeclause"></span>
              </td>
            </tr>
            <tr>
              <td
                class="tdLeft"
                colspan="4"
                style="text-align: center !important; font-weight: 600"
              >
                <span>能力最终等级：</span
                ><span
                  style="font-size: 18px; color: red"
                  id="finallevel"
                ></span>
              </td>
            </tr>
          </table>

          <div style="display: flex; padding: 20px 0px">
            <div><span>评估员签名：</span></div>
            <div style="margin-left: 300px"><span>日期：</span></div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <el-button type="warning" v-print="printObj" style="margin: 20px"  >打印</el-button> 
    </div>
  </div>
</template>

<script src="./js/capability-details.js"></script>
<style src="./css/capability-details.css" scoped></style>

<!-- // 去除打印的页眉页脚 -->
<style media="print">
@page {
  size: auto; /* auto is the initial value */
  margin: 3mm; /* this affects the margin in the printer settings */
}
</style>